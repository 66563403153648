<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Edit Pengguna</h4>
              <div class="flex-shrink-0">
                <router-link
                  :to="{ name: 'share.period' }"
                  type="button"
                  class="btn btn-warning waves-effect waves-light"
                  ><i
                    class="bx bx-chevrons-left font-size-16 align-middle me-2"
                  ></i>
                  Kembali
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <form class="" @submit.prevent="submit">
                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-label mt-2">Periode Saham</label>
                  </div>
                  <div class="col-md-5">
                    <input
                      v-model="share_period.name"
                      type="text"
                      class="form-control"
                      required
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-label mt-2">Harga</label>
                  </div>
                  <div class="col-md-4">
                    <input
                      v-model="share_period.price"
                      type="text"
                      class="form-control"
                      required
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-label mt-2">Jumat Slot</label>
                  </div>
                  <div class="col-md-3">
                    <input
                      v-model="share_period.slot_quota"
                      type="text"
                      class="form-control"
                      required
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-label mt-2">Tanggal Buka</label>
                  </div>
                  <div class="col-md-4">
                    <input
                      v-model="share_period.opened_at"
                      type="date"
                      class="form-control"
                      required
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-label mt-2">Tanggal Tutup</label>
                  </div>
                  <div class="col-md-4">
                    <input
                      v-model="share_period.closed_at"
                      type="date"
                      class="form-control"
                      required
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-2">
                    <label class="form-label mt-2">Status AKrif</label>
                  </div>
                  <div class="col-md-4">
                    <select
                      class="form-select"
                      v-model="share_period.is_active"
                      required
                    >
                      <option value="">Pilih Status Aktif</option>
                      <option value="1">Aktif</option>
                      <option value="0">Non Aktif</option>
                    </select>
                  </div>
                </div>

                <div class="float-end">
                  <router-link
                    :to="{ name: 'share.period' }"
                    type="button"
                    class="btn btn-warning waves-effect waves-light me-2"
                    ><i
                      class="bx bx-chevrons-left font-size-16 align-middle me-2"
                    ></i>
                    Kembali
                  </router-link>
                  <button
                    :disabled="load"
                    type="submit"
                    class="btn btn-primary waves-effect waves-light"
                  >
                    <i
                      class="bx font-size-16 align-middle me-2"
                      :class="{ 'bx-loader bx-spin': load, 'bx-save': !load }"
                    ></i>
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpService from "../../services/http-service";
import { endpoint } from "../../host";
import Swal from "sweetalert2";
import errorService from "../../services/error-service";
export default {
  components: {},
  created() {
    let params = this.$route.params;
    if (params.id !== undefined) {
      this.assignParams(params);
    }
  },
  data() {
    return {
      title: null,
      load: false,
      share_period: {
        id: null,
        name: null,
        price: null,
        slot_quota: null,
        opened_at: null,
        closed_at: null,  
        is_active: "0",
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.load = true;
        let res = await httpService.post(
          endpoint["share.period"] +`/${this.share_period.id}`,
          this.share_period,
          {
            headers: httpService.authHeader(),
          }
        );
        this.load = false;
        if (res.status == 200) {
          Swal.fire({
            title: "Berhasil",
            text: "data berhasil disimpan",
            icon: "success",
          });
          this.$router.push({
            name: "share.period",
            params: {
              reload: true,
            },
          });
        }
      } catch (error) {
        this.load = false;
        errorService.displayError(error);
      }
    },

    assignParams(params) {
      console.log(params);
      this.share_period.id = params.id;
      this.share_period.name = params.name;
      this.share_period.price = params.price;
      this.share_period.slot_quota = params.slot_quota;
      this.share_period.opened_at = params.opened_at;
      this.share_period.closed_at = params.closed_at;
      this.share_period.is_active = params.is_active;
      this.share_period._method = params.id ? "PUT" : "POST";
    },
  },
};
</script>

<style>
</style>